// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB-eY1JpIlAO5P1qgMp0XRgvovaKQsTPd8",
  authDomain: "ultimate-academy-ab7e5.firebaseapp.com",
  projectId: "ultimate-academy-ab7e5",
  storageBucket: "ultimate-academy-ab7e5.appspot.com",
  messagingSenderId: "894817502767",
  appId: "1:894817502767:web:a01c8c1219ab878a9b28da"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;