import React from 'react'
import Navbar from '../../Components/Navbar';
import NavStatusbar from '../../Components/NavStatusbar';
import MERNStackDevelopment from '../../Components/MERNStackDevelopment';
import Roadmap from '../../Components/Roadmap';
import Form from '../../Components/Form';
import './oracleStyles.css'
import Footer from '../../Components/Footer';
const OraclePage = () => {
  return (
    <div className='PageWraper'>
      <Navbar />
      <NavStatusbar courseName={'Oracle APEX'}/>
      <MERNStackDevelopment title="Oracle APEX" titleDiscpt="Become a master in Oracle APEX with our comprehensive course." />
      {/* <Roadmap /> */}
      <Roadmap
        //this two below are are left childs
        title="Advanced SQL and Introduction to PL/SQL" description=" Advanced Query Techniques (Week 1-2),  Indexing, Performance Optimization, and Basic PL/SQL (Week 3-4)"
        title2="Oracle APEX Advanced Features and Final Projects" description2="APEX Advanced Features (Week 1-2)"
        //this below are the right childs
        title3="Oracle SQL Fundamentals" description3="Week 1-2: Introduction to Databases and SQL Week 3-4: Filtering and Sorting Data"
        title4=" PL/SQL Advanced Concepts and Oracle APEX Basics" description4="Advanced PL/SQL Topics (Week 1-2) Oracle APEX Basics (Week 3-4)"
        title5="Final Projects and Hands-on Practice" description5="Encourage hands-on practice and real-world application."
        />

      <div className='oracle_course_details'>
        <div className='details_row_1'>
          <div style={{width: '60%'}}> 
            <div className='course_heading'>
              <div className='check_box'></div>
              <h1>Introduction</h1>
            </div>
            <ul>
              <li>Overview of relational databases and Oracle Database</li>
              <li>Basic SQL syntax</li>
              <li>Introduction to the SELECT statement and its clauses</li>
              <li>Introduction to the SELECT statement and its clauses, including WHERE for filtering, ORDER BY for sorting, and DISTINCT keyword</li>
              <li>Introduction to the SELECT statement and its clauses, including WHERE for filtering, ORDER BY for sorting, DISTINCT keyword, and JOIN operations (INNER JOIN, LEFT JOIN, RIGHT JOIN)</li>
            </ul>
          </div>
          <div style={{width: '60%'}}> 
            <div className='course_heading'>
              <div className='check_box'></div>
              <h1>Advance SQL and Basics of PL/SQL</h1>
            </div>
            <ul>
              <li>Utilize GROUP BY and HAVING clauses along with aggregation functions (SUM, AVG, COUNT, etc.) and CASE statements for effective data analysis and summarization in SQL.</li>
              <li>Understanding indexes</li>
              <li>Query optimization strategies</li>
              <li>Introduction to PL/SQL basics</li>
              <li>Writing and executing PL/SQL blocks</li>
              
            </ul>
          </div>
          <div style={{width: '60%'}}> 
            <div className='course_heading'>
              <div className='check_box'></div>
              <h1>PL/SQL Advanced and Oracle APEX Basics</h1>
            </div>
            <ul>
              <li>Stored procedures and functions</li>
              <li>Exception handling</li>
              <li>Triggers and packages</li>
              <li>Introduction to Oracle APEX</li>
              <li>Creating an APEX workspace and application</li>
              <li>Building forms and reports</li>
            </ul>
          </div>
        </div>
        <div className='details_row_2'>
          <div>
            <div className='course_heading'>
              <div className='check_box'></div>
              <h1>Oracle APEX Advanced </h1>
            </div>
            <ul>
              <li>Dynamic actions and validations</li>
              <li>Master-detail forms</li>
              <li>Interactive Grids and Charts</li>
          
            </ul>
          </div>
          <div>
            <div className='course_heading'>
              <div className='check_box'></div>
              <h1>Live project</h1>
            </div>
            <ul>
              <li>Assign a comprehensive project that incorporates SQL, PL/SQL, and Oracle APEX concepts.</li>
              <li>Encourage hands-on practice and real-world application.</li>
              {/* <li>Contributing to open source</li> */}
      
            </ul>
          </div>
        </div>
      </div>
      <Form />
      <Footer />
    </div>
  )
}

export default OraclePage;
