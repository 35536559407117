import React from 'react'
import './styles.css'
import Icons from '../Icons'

const Footer = () => {
  return (
    <footer className="footer">
        <div className="fullFooterSection">
          <div className="logosection">
            <img src={require('./Ultimatesvg.png')} alt="this is an image"/>
            {/* <h3>Ultimate Academy</h3> */}
            <p>Empowering minds, shaping futures  Your journey to tech excellence starts here.</p>
          </div>
          <div className="Footercourses">
            <ul>
              <li>
                <h4 id="h4styles">Courses</h4>
                <p>MERN Stack</p>
                <p>AWS Cloud</p>
              </li>
            </ul>
            <ul>
            <Icons />
            </ul>
          </div>
        </div>
        {/* this is for the line */}
        <div className="footerline"></div>
        {/* this for the copy rights */}
        <div className="copyrights">
          <p>© 2023 Ultimate Academy Inc. All rights reserved.</p>
        </div>
      </footer>
  )
}

export default Footer
