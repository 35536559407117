import React from 'react'
import './styles.css'

const MERNStackDevelopment = (props) => {
  return (
    <div className='MsdContainer'>
      <h1>{props.title}</h1>
      <p>{props.titleDiscpt}</p>
      <div className='boxWrapper'>
        <div className='boxWrapper_topdiv'>
          Includes:
        </div>
        <div className='boxWrapper_bottomdiv'>
          <ul>
            <div id='ul_div'>
              <img src={require('./Done_img.png')} alt='this is the done image' />
              <li>Hands-on projects</li>
            </div>
            <div id='ul_div'>
              <img src={require('./Done_img.png')} alt='this is the done image' />
              <li>Flexible schedule</li>
            </div>
            <div id='ul_div'>
              <img src={require('./Done_img.png')} alt='this is the done image' />
              <li>Community support</li>
            </div>
            <div id='ul_div'>
              <img src={require('./Done_img.png')} alt='this is the done image' />
              <li>Interactive learning</li>
            </div>
          </ul>
          <ul>
            <div id='ul_div'>
              <img src={require('./Done_img.png')} alt='this is the done image' />
              <li>Career Development Resources</li>
            </div>
            <div id='ul_div'>
              <img src={require('./Done_img.png')} alt='this is the done image' />
              <li>Mentorship and Guidance</li>
            </div>
            <div id='ul_div'>
              <img src={require('./Done_img.png')} alt='this is the done image' />
              <li>Real World Application</li>
            </div>
            <div id='ul_div'>
              <img src={require('./Done_img.png')} alt='this is the done image' />
              <li>Certification upon Completion</li>
            </div>
          </ul>
          
        </div>
      </div>
      <div className='logoholder'>
        <div id='logodiv'>
            <img src={require('./Mongologo.png')} />
        </div>
        <div id='logodiv'>
            <img src={require('./expresslogo.png')} />
        </div>
        <div id='logodiv'>
            <img src={require('./reactlogo.png')} />
        </div>
        <div id='logodiv'>
            <img src={require('./nodelogo.png')} />
        </div>
        
      </div>
    </div>
  )
}

export default MERNStackDevelopment
