import React from 'react'
import Navbar from '../../Components/Navbar';
import NavStatusbar from '../../Components/NavStatusbar';
import MERNStackDevelopment from '../../Components/MERNStackDevelopment';
import Roadmap from '../../Components/Roadmap';
import Form from '../../Components/Form';
import './styles.css'
import Footer from '../../Components/Footer';
const ReactPage = () => {
  return (
    <div className='PageWraper'>
      <Navbar />
      <NavStatusbar courseName={"AWS"}/>
      <MERNStackDevelopment title="AWS Cloud" titleDiscpt="Become a master in AWS Cloud Computing with our comprehensive course."/>
      <Roadmap
        title="Fundamentals" description="Mastering the fundamentals of aws services for cloud practitioner course"
        title2="Exam preparation" description2="Practice tests & assignments for aws certification"
        title3="Introduction to aws cloud" description3="Understanding what is cloud & overview of aws"
        title4="Hands on aws" description4="Once you understand the fundamentals, level up with hands on experience with aws services essential for Aws cloud Practitioner"
        title5="Live project" description5="Real world project."
        />
      <div className='course_details'>
        <div className='details_row_1'>
          <div> 
            <div className='course_heading'>
              <div className='check_box'></div>
              <h1>Introduction</h1>
            </div>
            <ul>
              <li>What is cloud</li>
              <li>cloud deployment models</li>
              <li>AWS Cloud value proposition</li>
              <li>Methods of interacting with AWS</li>
              <li>Architectural principles</li>
              <li>Well-Architected Framework</li>
              <li> Shared Responsibility model</li>
            </ul>
          </div>
          <div>
            <div className='course_heading'>
              <div className='check_box'></div>
              <h1>Fundamentals</h1>
            </div>
            <ul>
              <li>Foundational services</li>
              <li>Database services</li>
              <li>Management services</li>
              
            </ul>
          </div>
          <div>
            <div className='course_heading'>
              <div className='check_box'></div>
              <h1>Hands on Experience</h1>
            </div>
            <ul>
              <li>Amazon Elastic Compute Cloud (EC2)</li>
              <li>Amazon Virtual Private Cloud (VPC)</li>
              <li>Amazon Simple Storage Service (S3)</li>
              <li>Elastic Load Balancing (ELB)</li>
            </ul>
          </div>
        </div>
        <div className='details_row_2'>
          <div>
            <div className='course_heading'>
              <div className='check_box'></div>
              <h1>Exam preparation</h1>
            </div>
            <ul>
              <li>Practice tests</li>
              <li>Assignments</li>
              <li>Mock interviews</li>
          
            </ul>
          </div>
          <div>
            <div className='course_heading'>
              <div className='check_box'></div>
              <h1>Live project</h1>
            </div>
            <ul>
              <li>hands on experience on AWS</li>
              <li>Contributing to live project</li>
              <li>Contributing to open source</li>
      
            </ul>
          </div>
        </div>
      </div>
      <Form />
      <Footer />
    </div>
  )
}

export default ReactPage;
