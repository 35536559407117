import React from 'react'
import './index.css'
import app from '../FireBaseConfig.js'
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { useState } from 'react';

const Form = () => {
  const db = getFirestore(app);
  const [name, setName] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [email, setEmail] = useState('')
  const [showModal, setShowModal] = useState(false);

  const handleSubmitFormdata = (e) => {
    e.preventDefault()
    if((name === '') || (mobileNumber === '') || (email === '')) {
      alert("Please Fill all the field")
      return;
    }
    const dataCollRef = collection(db, 'Visitors') 
    addDoc(dataCollRef, { name, mobileNumber, email })
    .then( response => {
      setShowModal(true)
    }).catch(error => {
      console.log(error.message)
    })
  }

  const CloseModal = () => {
    setShowModal(false)
  }
  return (
    // <div className='formWrapper'>
      <div className='form'>
        <p><span>Join</span> our Learning Network</p>
        <form className='formstyle'>
            <span id='placenames'>Name</span>
            <input 
                type='text'
                placeholder='Ultimate'
                onChange={e => setName(e.target.value)}
                />
            <span id='placenames'>Mobile Number</span>
            <input 
                type='number'
                placeholder='+91 '
                onChange={e => setMobileNumber(e.target.value)}
                />
            <span id='placenames'>Email</span>
            <input 
                type='text'
                placeholder='Ultimate@ultimate.com'
                onChange={e => setEmail(e.target.value)}
                />
        </form>
        <button className='formbutton' onClick={handleSubmitFormdata}>Join</button>

        {showModal && (
        <div className="modal">
          <span className="close" onClick={CloseModal}>&times;</span>
          <div className="modal-content">
            
            <p>Thank's for your Intrest</p>
          </div>
        </div>
      )}
      </div>
        
    /* </div> */
  )
}

export default Form
