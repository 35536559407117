import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import './styles.css'

const Icons = () => {
  return (
    <div className='iconsContainer'>
      <InstagramIcon style={{color: 'white'}}/>
      <FacebookIcon style={{color: 'white'}}/>
    </div>
  )
}

export default Icons
