import React, { useState, useEffect } from 'react'
import './styles.css'

const Roadmap = (props) => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='roadmap_main'>
      <div className='roadmap_child1'>
        <h2>The Curriculum Roadmap</h2>
        <p>DesignCourse is structured to providing aspiring designers with the necessary foundation to produce beautiful user interfaces</p>
      </div>
      {screenSize > 900 ? (
        <div className='roadmap_child2_main'>{/*this div will do flex row */}
          <div className='roadmap_child2_leftchild'> {/*this is the right content div */}
            <div className='roadmap_leftchild_content_container'>
              <h1>{props.title}</h1>
              <p>{props.description}</p>
            </div>
            <div className='roadmap_leftchild_content_container'>
                {/* <h1>Exam preparation</h1>
                <p>Practice tests & assignments for aws certification</p> */}
                <h1>{props.title2}</h1>
                <p>{props.description2}</p>
            </div>
            
          </div> 
          <div className='roadmap_child2_middlechild '>
            <img src={require('./roadmap_line.png')} />
          </div>
          <div className='roadmap_child2_rightchild'>{/*this is the right content div */}
            
            <div className='roadmap_rightchild_content_container'>
              {/* <h1>Introduction to aws cloud</h1>
              <p>Mastering the fundamentals of aws services for cloud practitioner course</p> */}
              <h1>{props.title3}</h1>
              <p>{props.description3}</p>
              
            </div>
            <div className='roadmap_rightchild_content_container'>
                {/* <h1>Hands on aws</h1>
                <p>Once you understand the fundamentals, level up with hands on experience with aws services essential for Aws cloud Practitioner
</p> */}
                <h1>{props.title4}</h1>
                <p>{props.description4}</p>
            </div>
            <div className='roadmap_rightchild_content_container'>
                {/* <h1>Live project</h1>
                <p>Real world project.</p> */}
                <h1>{props.title5}</h1>
                <p>{props.description5}</p>
            </div>
            
          </div>
        </div>
      ) : (
        <div className='after_roadmap_child2_main'>
          <div className='after_roadmap_child2_main_img'>
            <img src={require('./roadmap_line.png')} alt='this is an image' />
          </div>
          <div className='after_roadmap_child2_rightchild'>
            <div className='after_roadmap_rightchild_content_container'>
              {/* <h1>Introduction to aws cloud</h1>
              <p>Mastering the fundamentals of aws services for cloud practitioner course</p> */}
              <h1>{props.title}</h1>
              <p>{props.description}</p>
            </div>
            <div className='after_roadmap_rightchild_content_container'>
              {/* <h1>Introduction to aws cloud</h1>
              <p>Mastering the fundamentals of aws services for cloud practitioner course</p> */}
              <h1>{props.title2}</h1>
              <p>{props.description2}</p>
            </div>
            <div className='after_roadmap_rightchild_content_container'>
              {/* <h1>Introduction to aws cloud</h1>
              <p>Mastering the fundamentals of aws services for cloud practitioner course</p> */}
              <h1>{props.title3}</h1>
              <p>{props.description3}</p>
            </div>
            <div className='after_roadmap_rightchild_content_container'>
              {/* <h1>Introduction to aws cloud</h1>
              <p>Mastering the fundamentals of aws services for cloud practitioner course</p> */}
              <h1>{props.title4}</h1>
              <p>{props.description4}</p>
            </div>
            <div className='after_roadmap_rightchild_content_container'>
              {/* <h1>Introduction to aws cloud</h1>
              <p>Mastering the fundamentals of aws services for cloud practitioner course</p> */}
              <h1>{props.title5}</h1>
              <p>{props.description5}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Roadmap


