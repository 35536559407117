import React from 'react'
import Navbar from '../../Components/Navbar';
import NavStatusbar from '../../Components/NavStatusbar';
import MERNStackDevelopment from '../../Components/MERNStackDevelopment';
import Roadmap from '../../Components/Roadmap';
import Form from '../../Components/Form';
import './styles.css'
import Footer from '../../Components/Footer';
const ReactPage = () => {
  return (
    <div className='PageWraper'>
      <Navbar />
      <NavStatusbar courseName={'MERN Stack'}/>
      <MERNStackDevelopment title="MERN Stacks" titleDiscpt="Become a master in MERN Stack Development with our comprehensive course." />
      {/* <Roadmap /> */}
      <Roadmap
        //this two below are are left childs
        title="MongoDB and Backend Development" description="Week 1-2: Introduction to MongoDB, Week 3-4: Integrating MongoDB with Express.js"
        title2="Full-Stack Integration and Advanced Topics" description2="Week 1-2: Full-Stack Integration Week 3-4: Advanced Topics and Deployment"
        //this below are the right childs
        title3="Foundations of Web Development and Backend" description3="Week 1-2: Introduction to Web Development Week 3-4: Backend Basics with Node.js and Express"
        title4="React Fundamentals" description4="Week 1-2: Introduction to React Week 3-4: Building Complex UIs with React"
        title5="Projects and Portfolio" description5="Encourage learners to work on real-world projects, applying the knowledge gained during each phase"
        />

      <div className='course_details'>
        <div className='details_row_1'>
          <div> 
            <div className='course_heading'>
              <div className='check_box'></div>
              <h1>Introduction</h1>
            </div>
            <ul>
              <li>HTML, CSS, and JavaScript fundamentals</li>
              <li>Introduction to basic web development concepts and tools</li>
              <li>Setting up Node.js and NPM</li>
              <li>Building a simple server with Express.js</li>
              <li>RESTful API concepts</li>
            </ul>
          </div>
          <div>
            <div className='course_heading'>
              <div className='check_box'></div>
              <h1> MongoDB and Backend</h1>
            </div>
            <ul>
              <li>Understanding NoSQL databases</li>
              <li>Setting up and connecting to MongoDB</li>
              <li>CRUD operations with MongoDB</li>
              <li>Building a backend API with Express.js and MongoDB</li>
              <li>Handling user authentication and authorization</li>
              
            </ul>
          </div>
          <div>
            <div className='course_heading'>
              <div className='check_box'></div>
              <h1>React Fundamentals</h1>
            </div>
            <ul>
              <li>Understanding React components and JSX</li>
              <li>State, props, and component lifecycle</li>
              <li>Building simple React applications</li>
              <li>React Router for navigation</li>
              <li>State management with Context API or Redux</li>
              <li>Introduction to styling in React (CSS-in-JS, styled-components)</li>
            </ul>
          </div>
        </div>
        <div className='details_row_2'>
          <div>
            <div className='course_heading'>
              <div className='check_box'></div>
              <h1>Full-Stack Integration</h1>
            </div>
            <ul>
              <li>Integrating the frontend (React) with the backend (Node.js and Express)</li>
              <li>Consuming APIs and handling data in React</li>
              <li>Advanced backend concepts (middlewares, error handling, etc.)</li>
              <li>Deployment strategies for MERN stack applications (Heroku, Netlify, MongoDB Atlas)</li>
              <li>Best practices, performance optimization, and security considerations</li>
          
            </ul>
          </div>
          <div>
            <div className='course_heading'>
              <div className='check_box'></div>
              <h1>Live project</h1>
            </div>
            <ul>
              <li>hands on experience on AWS</li>
              <li>Contributing to live project</li>
              <li>Contributing to open source</li>
      
            </ul>
          </div>
        </div>
      </div>
      <Form />
      <Footer />
    </div>
  )
}

export default ReactPage;
