import React from 'react'
import './styles.css'
import HeavyCard from '../../Components/HeavyCard';
import Form from "../../Components/Form";
import Navbar from '../../Components/Navbar';
import Hero from '../../Components/Hero';
import Icons from '../../Components/Icons';


import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../Components/Footer';

const HomePage = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("oracleAPEX");
  }

  const scrollToForm = () => {
    const courseSection = document.getElementById('JoinOurNetwork');
    if(courseSection){
      courseSection.scrollIntoView({behavior: 'smooth'})
    }
  }

  const width = window.innerWidth;

  return (
    <div>
      <Navbar />
      <Hero />
      
      {
        width > 600 ? (
          <>
          <div id= "courses" className="featureCourses">
            <HeavyCard
              styleName={'Coursecontent'}
              Imge={'cardImage2.png'}
              title='MERN'
              disc={`MERN stack comprises MongoDB, Express.js, React.js, and Node.js, offering a robust, 
                full-stack solution for building powerful and scalable web applications`}
              to={"/reactpage"}
            />
            <div className="divisionLine"></div>
            <HeavyCard
              styleName={'Coursecontent'}
              Imge={'aws.png'}
              title='AWS'
              disc={`AWS, Amazon's cloud computing platform, offers a vast selection of on-demand services, empowering businesses 
                with scalable solutions for computing, storage, and networking needs globally.`}
              to={"/aws"}
            />
          </div>

          {/* <div className={screenSize >= 460 ? ('oracle_card') : ('oracle_card2')}>
            <img src={require('./Oracle_img.png')} alt='this is the oracle image' />
            <h1>Oracle Apex</h1>
            <p>
              AWS, Amazon's cloud computing platform, offers a vast selection of on-demand services, 
              empowering businesses with scalable solutions for computing, storage, and networking 
              needs globally.
            </p>
            <div className='oracle_card_child1'>
              <button onClick={scrollToForm}>Join</button>
              <span>or</span>
              <a onClick={handleRedirect}>explore Course</a>
            </div>
          </div> */}
          <HeavyCard 
              styleName={'Coursecontent'}
              Imge={'Oracle_img.png'}
              imageWidth={'50%'}
              title={'Oracle'}
              disc={`MERN stack comprises MongoDB, Express.js, React.js, and Node.js, offering a robust, 
                full-stack solution for building powerful and scalable web applications`}
              to={'/oracleAPEX'} />
          </>
        ) : (
          <div id= "courses" className="featureCourses2">
            <HeavyCard
              styleName={'Coursecontent'}
              Imge={'cardImage2.png'}
              title='MERN'
              disc={`MERN stack comprises MongoDB, Express.js, React.js, and Node.js, offering a robust, 
                full-stack solution for building powerful and scalable web applications`}
              to={"/reactpage"}
            />
            <div className="divisionLine"></div>
            <HeavyCard
              styleName={'Coursecontent'}
              Imge={'aws.png'}
              title='AWS'
              disc={`AWS, Amazon's cloud computing platform, offers a vast selection of on-demand services, empowering businesses 
                with scalable solutions for computing, storage, and networking needs globally.`}
              to={"/aws"}
            />

            {/* <div className={screenSize >= 460 ? ('oracle_card') : ('oracle_card2')}>
              <img src={require('./Oracle_img.png')} alt='this is the oracle image' />
              <h1>Oracle Apex</h1>
              <p>
                AWS, Amazon's cloud computing platform, offers a vast selection of on-demand services, 
                empowering businesses with scalable solutions for computing, storage, and networking 
                needs globally.
              </p>
              <div className='oracle_card_child1'>
                <button onClick={scrollToForm}>Join</button>
                <span>or</span>
                <a onClick={handleRedirect}>explore Course</a>
              </div>
            </div> */}
            <HeavyCard 
              styleName={'Coursecontent'}
              Imge={'Oracle_img.png'}
              imageWidth={'50%'}
              title={'Oracle'}
              disc={`MERN stack comprises MongoDB, Express.js, React.js, and Node.js, offering a robust, 
                full-stack solution for building powerful and scalable web applications`}
              to={'/oracleAPEX'} 
            />

          </div>
        )
      }

      {/* this id is given for the scroll to feature */}
      <div id='JoinOurNetwork'> 
        <Form />
      </div>
      <Footer />
    </div>
  )
}

export default HomePage
