import React from 'react'
import './styles.css'

//i could have addded this in Reactpage but i did seperatly anyway
const NavStatusbar = ({courseName}) => {
  return (
    <div className='ContainerWapper'>
      {/* i am just adding the names statically for time sake later it should be changed dynamically */}
      <p>Courses</p>
      <p>&gt;</p>
      <p>{courseName}</p>
    </div>
  )
}

export default NavStatusbar
